import React from 'react';
import {Link} from 'react-router-dom';
import WelcomeComponent from '../common/Welcome.component';
import {connect} from 'react-redux';

class Header extends React.Component {
  render() {
    const {user} = this.props;
    return (
      <>
        <header className={`header `}>
          <div className="container-lg">
            <nav
              className="navbar navbar-expand-lg navbar-light"
              style={{flexWrap: 'nowrap'}}>
              <a
                className="navbar-brand"
                href="https://emiratescoin.pages.dev/">
                <img
                  style={{opacity: '0'}}
                  className="logo-img"
                  src={require('../../assets/images/logo.svg')}
                  title="Aqarchain"
                  alt="Aqarchain"
                />
              </a>

              <div
                className="collapse navbar-collapse justify-content-xl-end justify-content-around"
                id="navbarNav">
                <ul className="navbar-nav">
                  <li className={`nav-item `}>
                    <a
                      target="_blank"
                      className="nav-link"
                      href="https://emiratescoin-tokenization.pages.dev/discover">
                      Tokenize Properties
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#abc"
                      id="navbarLanguageLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Language
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarLanguageLink">
                      <a className="dropdown-item" href="#abc">
                        Arabic (عربى)
                      </a>
                      <a className="dropdown-item" href="#abc">
                        English
                      </a>
                    </div>
                  </li>
                  <li>
                    <div style={{display: 'flex', gap: '1rem'}}>
                      <a
                        href="https://emiratescoin-tokenization.pages.dev/login"
                        className="btn btn-gradient btn-rounded btn-sm w-100px"
                        type="button">
                        Login
                      </a>
                    </div>
                  </li>
                </ul>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
const mapStateToProps = ({app: {user}}) => {
  return {
    user: user,
  };
};
export default connect(mapStateToProps, null)(Header);
