import React from 'react';
import Dashboard from '../components/pages/Dashboard/Dashboard';
import Landing from '../components/pages/Landing/Landing';
import Home from '../components/pages/Home';
import LoginForm from '../components/pages/Login/Login';
import KycProcess from '../components/pages/KycProcess';
import CompanyRegistration from '../components/pages/CompanyRegistration';
import SearchView from '../components/pages/SearchView';
import PropertyDetails from '../components/pages/Property/PropertyDetails';
import Registration from '../components/pages/User/registration';
import Forgotpassword from '../components/pages/User/forgotpassword';
import Resetpassword from '../components/pages/User/resetpassword';
import Enquery from '../components/pages/Dashboard/Enquery/Enquery';
import Agent from '../components/pages/Dashboard/Agent/AgentManagment';
import AgentAdd from '../components/pages/Dashboard/Agent/AgentAdd';
import Profile from '../components/pages/Dashboard/Profile/Profile';
import CompanyProfile from '../components/pages/Dashboard/Profile/CompanyProfile';
import PropertyAdd from '../components/pages/Dashboard/Property/PropertyAdd';
import PropertyListing from '../components/pages/Dashboard/Property/PropertyListing';
import SaveProperties from '../components/pages/Dashboard/Property/SaveProperties';

import AppRoute from '../routing/AppRoute';
import PrivateRoute from '../routing/PrivateRoute';

import DashboardLayout from '../components/layout/DashboardLayout';
import PublicLayout from '../components/layout/PublicLayout';

import {Route, Switch} from 'react-router-dom';
import Authorization from './Authorization';
import KYC from '../components/pages/Dashboard/Kyc/Kyc';
import Tokenization from '../components/pages/Dashboard/Tokenization/Tokenization';
import Portfolio from '../components/pages/Dashboard/Portfolio/Portfolio';
import Wallet from '../components/pages/Dashboard/Wallet/Wallet';

import {store} from '../redux/store';
import PrivacyPolicy from '../components/pages/PrivacyPolicy';
import PageNotFound from '../components/pages/404Error';
import Investments from '../components/pages/Investments';
const ALLUser = Authorization(['AGENT', 'COMPANY', 'USER']);
const COMPANY = Authorization(['COMPANY', 'AGENT']);
const AGENT = Authorization(['AGENT']);
const OWNER = Authorization(['USER']);

const routes = (
  <>
    <Switch>
      <AppRoute exact path="/" component={Home} layout={PublicLayout} />
      {/* <AppRoute
        exact
        path="/privacy-policy"
        component={PrivacyPolicy}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/login"
        component={LoginForm}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/kyc-process"
        component={KycProcess}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/company-registration"
        component={CompanyRegistration}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/investment"
        component={Investments}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/search-view"
        component={SearchView}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/property-detail/:id"
        component={PropertyDetails}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/user-registration"
        component={Registration}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/forgotpassword"
        component={Forgotpassword}
        layout={PublicLayout}
      />
      <AppRoute
        exact
        path="/reset/:token"
        component={Resetpassword}
        layout={PublicLayout}
      /> */}
      {/* {/ <Route exact path="/tokenization"  component={Tokenization} /> /} */}
      {/* {/ <AppRoute exact path='/dashboard' component={Dashboard} layout={DashboardLayout} /> /} */}
      {/* <PrivateRoute
        exact
        path="/dashboard"
        component={ALLUser(Dashboard)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/enquery"
        component={ALLUser(Enquery)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/agent"
        component={Agent}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/agent/add"
        component={AgentAdd}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/profile"
        component={ALLUser(Profile)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/company-profile"
        component={CompanyProfile}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/property-add"
        component={PropertyAdd}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/property-listing"
        component={ALLUser(PropertyListing)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/save-properties"
        component={ALLUser(SaveProperties)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/kyc"
        component={OWNER(KYC)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/tokenization"
        component={OWNER(Tokenization)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/portfolio"
        component={OWNER(Portfolio)}
        layout={DashboardLayout}
      />
      <PrivateRoute
        exact
        path="/dashboard/wallet"
        component={OWNER(Wallet)}
        layout={DashboardLayout}
      /> */}
      <Route path="*" component={PageNotFound} />
    </Switch>
  </>
);

export default routes;
